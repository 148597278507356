import { createSubjectId, PricesType, PricingCart, PricingCartSubject } from '@sprinx/pricing-core';
import PricingConstants from '@sprinx/pricing-core/constants';
import { Multicurrency } from '@sprinx/react-globalize/types';
import useTranslate from '@sprinx/react-globalize/useTranslate';
import { useCallback, useMemo } from 'react';
import { atom, constSelector, selector, useRecoilState, useRecoilValue } from 'recoil';
import invariant from 'tiny-invariant';
import { Overwrite } from 'utility-types';
import { EntityId, Product, ProductQuantityLimits, ProductRecord } from '../../@sprinx/knihovka-types';
import { PaymentMethod, ShippingMethod } from '../../@sprinx/knihovka-types/shopping';
import { ApiClient } from '../../@sprinx/react-after-razzle';
import { GlobalStateRegister } from '../../@sprinx/react-after-razzle/stateStore';
import { apiClientState, currencyState, pricesTypeState } from '../appState';
import { useShowSnackbarMessage } from '../snackbars';

// Shopping settings

/**
 * IF `true` can not decrement in cart to 0 => remove cart item by delete/remove action.
 */
export const preventDecrementRemoveSubjectState = constSelector(true);
export const usePreventDecrementRemoveSubject = (): boolean => {
  return useRecoilValue(preventDecrementRemoveSubjectState);
};

/**
 * Keeps state of shopping cart.
 */
export const shoppingCartState = GlobalStateRegister.register(
  atom<ShoppingCart | undefined>({
    key: 'shoppingCart',
    default: undefined,
  }),
);

export const shoppingCartNumberOfSubjectsSelector = selector({
  key: 'shoppingCartnumberOfSubjectsSelector',
  get: ({ get }) => {
    const cart = get(shoppingCartState);
    return cart?.cartContent.subjects.length || 0;
  },
});

export const useShoppingCartPresentInCart = (): ((params: {
  product?: string | { id: string };
  ruleCode?: string;
  subjectId?: string;
}) => [quantity: number | undefined, subjectId: string]) => {
  const cart = useRecoilValue(shoppingCartState);

  return useCallback<
    (params: {
      product?: string | { id: string };
      ruleCode?: string;
      subjectId?: string;
    }) => [quantity: number | undefined, subjectId: string]
  >(
    ({ product, ruleCode, subjectId: pSubjectId }) => {
      invariant(
        pSubjectId || product,
        'AppShoppingProvider presentInCart requires at least one of subjectId or product have to be defined',
      );

      const subjectId = pSubjectId || createSubjectId(ensureId(product as any), ruleCode);
      return [(cart?.flatCart[subjectId] || {}).quantity, subjectId];
    },
    [cart?.flatCart],
  );
};

export const useShoppingCartQuantityInCart = (): ((params: {
  cartSubject?: { id: string; product: { id: string } };
  product?: { id: string };
  ruleCode?: string;
}) => number | undefined) => {
  const presentInCart = useShoppingCartPresentInCart();

  return useCallback<
    (params: {
      cartSubject?: { id: string; product: { id: string } };
      product?: { id: string };
      ruleCode?: string;
    }) => number | undefined
  >(
    ({ cartSubject, product, ruleCode }) => {
      const [qty] = presentInCart(
        cartSubject ? { subjectId: cartSubject.id, product: cartSubject.product } : { product, ruleCode },
      );
      return qty;
    },
    [presentInCart],
  );
};

/**
 * Returns handler to add quantity of product or cart subject to cart
 */
export function useAddToCart(
  {
    cartSubject,
    forcePreventDecrement = false,
    product,
    ruleCode,
  }: {
    cartSubject?: ShoppingCartSubject;
    forcePreventDecrement?: boolean;
    product?: AddToCartProduct;
    ruleCode?: string;
  },
  customQuantityLimitDefaults?: Partial<ProductQuantityLimits>,
): {
  addToCart: (nextQuantity: number) => Promise<any>;
  quantity: number | undefined;
} & ProductQuantityLimits {
  invariant(
    cartSubject || product,
    'useAddToCart requires at least one of `cartSubject` or `product` props to be defined.',
  );

  const updateCart = useUpdateCart();
  const preventDecrementRemoveSubject = usePreventDecrementRemoveSubject();
  const quantityInCart = useShoppingCartQuantityInCart();

  const quantity = cartSubject ? cartSubject.quantity : quantityInCart({ product, ruleCode });

  const handleAddToCart = useCallback<(nextQuantity: number) => Promise<any>>(
    (nextQuantity) => {
      if (preventDecrementRemoveSubject && !forcePreventDecrement && nextQuantity <= 0) {
        return Promise.resolve();
      }

      if (cartSubject) {
        return updateCart({ subjectId: cartSubject.id, product: cartSubject.product, quantity: nextQuantity });
      }

      if (product) {
        return updateCart({ product, quantity: nextQuantity, ruleCode });
      }

      return Promise.resolve();
    },
    [cartSubject, forcePreventDecrement, preventDecrementRemoveSubject, product, ruleCode, updateCart],
  );

  const o: any = cartSubject || product || {};

  const quantyLimits = useProductQuantityLimitsDefaults(
    {
      quantityDefault: o.quantityDefault,
      quantityMax: o.quantityMax,
      quantityMin: o.quantityMin,
      quantityStep: o.quantityStep,
      quantityStepRounding: o.quantityStepRounding,
    },
    customQuantityLimitDefaults,
  );

  return {
    addToCart: handleAddToCart,
    quantity,
    ...quantyLimits,
  };
}

export function useRemoveFromCart(options: {
  cartSubject?: ShoppingCartSubject;
  product?: AddToCartProduct;
  ruleCode?: string;
}): () => Promise<any> {
  const { addToCart } = useAddToCart({ ...options, forcePreventDecrement: true });
  return useCallback<() => Promise<any>>(() => {
    return addToCart(0);
  }, [addToCart]);
}

/**
 * Keeps after app and cart initialization decisition. If `state !=== undefined` is required
 * to do some decision. Merge carts, create new one or keep current.
 */
export const shoppingCartInitializationDecisionState = GlobalStateRegister.register(
  atom<ShoppingCartPreparationResult | undefined>({
    key: 'shoppingCartInitializationDecision',
    default: undefined,
  }),
);

/**
 * Shopping cart initialization
 */

export function useShoppingCartPrepareForSessionCall(): () => Promise<ShoppingCartPreparationResult> {
  const apiClient = useRecoilValue(apiClientState);
  return useCallback(() => shoppingCartPrepareForSessionCall(apiClient), [apiClient]);
}

export const shoppingCartPrepareForSessionCall = (apiClient: ApiClient): Promise<ShoppingCartPreparationResult> => {
  return apiClient.post<ShoppingCartPreparationResult, {}>('/v1/shopping-cart/prepare-cart-for-session', {});
};

/**
 * Shopping cart merge decision.
 */

export function useShoppingCartProcessUserDecisionCall(): (params: any) => Promise<ShoppingCartUserDecitionResult> {
  const apiClient = useRecoilValue(apiClientState);
  return useCallback(
    (params: ShoppingCartProcessUserDecisionCallParams) =>
      apiClient.post<ShoppingCartUserDecitionResult, ShoppingCartProcessUserDecisionCallParams>(
        '/v1/shopping-cart/process-user-decition',
        params,
      ),
    [apiClient],
  );
}

/**
 * Update shopping cart
 */
export function useShoppingCartUpdateCartCall(): (
  cartId: ShoppingCart['id'] | undefined | null,
  data: ShoppingCartUpdateCartDataItemParams | ShoppingCartUpdateCartDataItemParams[],
) => Promise<any> {
  const apiClient = useRecoilValue(apiClientState);
  const currency = useRecoilValue(currencyState);
  const pricesType = useRecoilValue(pricesTypeState);

  return useCallback(
    (
      cartId: ShoppingCart['id'] | undefined | null,
      data: ShoppingCartUpdateCartDataItemParams | ShoppingCartUpdateCartDataItemParams[],
    ) => {
      if (!cartId) {
        throw new Error('CART not Found');
      }
      return apiClient.post<ShoppingCartManipulationResult, ShoppingCartUpdateCartCallParams>(
        '/v1/shopping-cart/update',
        {
          cartId,
          currency,
          data,
          decimalPrecision: undefined,
          enabledDynamicRules: undefined,
          priceGroup: undefined,
          pricesType: pricesType,
        },
      );
    },
    [apiClient, currency, pricesType],
  );
}

/**
 * Reset shopping cart.
 */
export function useShoppingCartReserCartCall(): (
  cartId: ShoppingCart['id'] | undefined | null,
) => Promise<ShoppingCartManipulationResult> {
  const apiClient = useRecoilValue(apiClientState);
  return useCallback(
    (cartId: ShoppingCart['id'] | undefined | null) => {
      if (!cartId) {
        throw new Error('CART not Found');
      }

      return apiClient.post<ShoppingCartManipulationResult, { cartId: ShoppingCart['id'] }>('/v1/shopping-cart/reset', {
        cartId,
      });
    },
    [apiClient],
  );
}

export const useUpdateCart = (): ((
  data: ShoppingCartUpdateCartDataItemParams | ShoppingCartUpdateCartDataItemParams[],
) => Promise<ShoppingCart | undefined>) => {
  const [cart, setCart] = useRecoilState(shoppingCartState);
  const updateCartCall = useShoppingCartUpdateCartCall();
  const showCartUpdateMessage = useShowCartUpdateMessage();

  return useCallback(
    (data: ShoppingCartUpdateCartDataItemParams | ShoppingCartUpdateCartDataItemParams[]): Promise<any> => {
      return updateCartCall(cart?.id, data)
        .then((res) => {
          setCart(res.cart);
          if (res.updateStatus.msg) {
            showCartUpdateMessage(res.updateStatus);
          }

          return res.cart;
        })
        .catch((err) => {
          // TODO: ak nastala chyba updatu, tak co???
          console.error('Update Cart Error', err);
        });
    },
    [cart?.id, setCart, showCartUpdateMessage, updateCartCall],
  );
};

export const useCartReset = (): ((disableMessage?: boolean) => Promise<any>) => {
  const [cart, setCart] = useRecoilState(shoppingCartState);
  const resetCartCall = useShoppingCartReserCartCall();
  const showCartUpdateMessage = useShowCartUpdateMessage();

  return useCallback(
    (disableMessage?: boolean) => {
      return resetCartCall(cart?.id).then((res) => {
        setCart(res.cart);
        if (res.updateStatus.msg && !disableMessage) {
          showCartUpdateMessage(res.updateStatus);
        }
      });
    },
    [cart?.id, resetCartCall, setCart, showCartUpdateMessage],
  );
};

export const useShoppingCartEnableIndirectSubject = (): ((indirectSubjectId: string) => void) => {
  const updateCart = useUpdateCart();
  const cart = useRecoilValue(shoppingCartState);

  return useCallback(
    (indirectSubjectId: string) => {
      if (cart?.flatCart[indirectSubjectId]) {
        updateCart({ subjectId: indirectSubjectId, enableIndirect: true });
      }
    },
    [cart?.flatCart, updateCart],
  );
};

export const useShoppingCartSetPayment = (): ((
  cartPaymentProduct: string | ShoppingCartProduct | undefined,
) => Promise<any>) => {
  const updateCart = useUpdateCart();

  return useCallback<(cartPaymentProduct: string | ShoppingCartProduct | undefined) => Promise<any>>(
    (cartPaymentProduct) => {
      const cartPayment = cartPaymentProduct ? { product: cartPaymentProduct } : {};

      return updateCart({
        ...cartPayment,
        quantity: 1,
        subjectId: PricingConstants.PAYMENT_ID,
      });
    },
    [updateCart],
  );
};

export const useShoppingCartSetShipping = (): ((
  cartShippingProduct: string | ShoppingCartProduct | undefined,
) => Promise<any>) => {
  const updateCart = useUpdateCart();

  return useCallback<(cartShippingProduct: string | ShoppingCartProduct | undefined) => Promise<any>>(
    (cartShippingProduct) => {
      const cartShipping = cartShippingProduct ? { product: cartShippingProduct } : {};
      return updateCart({ ...cartShipping, quantity: 1, subjectId: PricingConstants.SHIPPING_ID });
    },
    [updateCart],
  );
};

export const justifyQuantity = (
  { quantityMax, quantityMin, quantityStep, quantityStepRounding }: Omit<ProductQuantityLimits, 'quantityDefault'>,
  quantity: number | undefined,
): number => {
  const getRoundingFunction = (quantityStepRounding: ProductQuantityLimits['quantityStepRounding']) => {
    if (quantityStepRounding === 'down') {
      return Math.floor;
    }

    // quantityStepRounding === 'up'
    return Math.ceil;
  };

  if (quantity === undefined || quantity <= 0) return 0;

  if (quantityStep > 1) {
    const fraction = quantity / quantityStep;
    const fold = getRoundingFunction(quantityStepRounding)(fraction);

    return Math.min(Math.max(fold * quantityStep, quantityMin), quantityMax);
  }

  return Math.min(Math.max(quantity, quantityMin), quantityMax);
};

export function useJustifyQuantity({
  quantityMax,
  quantityMin,
  quantityStep,
  quantityStepRounding,
}: Omit<ProductQuantityLimits, 'quantityDefault'>): (quantity: number | undefined) => number {
  return useCallback<(quantity: number | undefined) => number>(
    (quantity) => justifyQuantity({ quantityMax, quantityMin, quantityStep, quantityStepRounding }, quantity),
    [quantityMax, quantityMin, quantityStep, quantityStepRounding],
  );
}

export function productQuantityLimitsDefaults(
  productLimits: Partial<ProductQuantityLimits>,
  customDefaults?: Partial<ProductQuantityLimits>,
): ProductQuantityLimits {
  const {
    quantityMax = customDefaults?.quantityMax || Number.MAX_SAFE_INTEGER,
    quantityMin = customDefaults?.quantityMin || 1,
    quantityStep = customDefaults?.quantityStep || 1,
    quantityStepRounding = customDefaults?.quantityStepRounding || 'up',
  } = productLimits;

  const quantityDefault: number =
    productLimits.quantityDefault || customDefaults?.quantityDefault || productLimits?.quantityMin || 1;

  return { quantityDefault, quantityMax, quantityMin, quantityStep, quantityStepRounding };
}

export function useProductQuantityLimitsDefaults(
  productLimits: Partial<ProductQuantityLimits>,
  customDefaults?: Partial<ProductQuantityLimits>,
): ProductQuantityLimits {
  return useMemo<ProductQuantityLimits>(
    () => productQuantityLimitsDefaults(productLimits, customDefaults),
    [customDefaults, productLimits],
  );
}

export const useShowCartUpdateMessage = (): ((updateStatus: ShoppingCartManipulationResultUpdateStatus) => void) => {
  const t = useTranslate();
  const showSnackbarMessage = useShowSnackbarMessage();
  return useCallback(
    (updateStatus: ShoppingCartManipulationResultUpdateStatus): void => {
      const statusToText = {
        'all-removed': () => t('shoppingCart/messages/cartManipulations/allRemoved'),
        'item-added': () => t('shoppingCart/messages/cartManipulations/itemAdded', { number: updateStatus.qty || 0 }),
        'item-removed': () =>
          t('shoppingCart/messages/cartManipulations/itemRemoved', { number: updateStatus.qty || 0 }),
        'quantity-chaged': () => t('shoppingCart/messages/cartManipulations/quantityChaged'),
        'invalid-coupon': () => t('shoppingCart/messages/cartManipulations/invalidCoupon'),
        'invalid-product-quantity': () => t('shoppingCart/messages/cartManipulations/invalidProductQuantity'),
        'invalid-product': () => t('shoppingCart/messages/cartManipulations/invalidProduct'),
      };

      if (!updateStatus.msg) return;

      const toMsg = statusToText[updateStatus.msg];
      if (toMsg) {
        showSnackbarMessage(toMsg(), {
          variant: updateStatus.msg.startsWith('invalid-') ? 'error' : 'info',
          preventDuplicate: true,
        });
      }
    },
    [showSnackbarMessage, t],
  );
};

/**
 * Reset shopping cart.
 */
export function useShoppingCartAddDiscountCouponCall(): (
  cartId: ShoppingCart['id'] | undefined | null,
  couponCode: string,
) => Promise<ShoppingCartManipulationResult> {
  const apiClient = useRecoilValue(apiClientState);
  const currency = useRecoilValue(currencyState);
  const pricesType = useRecoilValue(pricesTypeState);
  return useCallback(
    (cartId: ShoppingCart['id'] | undefined | null, couponCode: string) => {
      if (!cartId) {
        throw new Error('CART not Found');
      }

      return apiClient.post<
        ShoppingCartManipulationResult,
        {
          cartId: ShoppingCart['id'];
          couponCode: string;
          currency: string;
          pricesType: 'B2B' | 'B2C' | 'B2BForeign' | undefined;
        }
      >('/v1/shopping-cart/add-discount-coupon', {
        cartId,
        couponCode,
        currency,
        pricesType: pricesType,
      });
    },
    [apiClient, currency, pricesType],
  );
}

export const useCartAddDiscountCoupon = (): ((couponCode: string, disableMessage?: boolean) => Promise<any>) => {
  const [cart, setCart] = useRecoilState(shoppingCartState);
  const addDiscountCouponCall = useShoppingCartAddDiscountCouponCall();
  const showCartUpdateMessage = useShowCartUpdateMessage();

  return useCallback(
    (couponCode: string, disableMessage?: boolean) => {
      return addDiscountCouponCall(cart?.id, couponCode).then((res) => {
        if (!(res.updateStatus.msg && res.updateStatus.msg === 'invalid-coupon')) {
          setCart(res.cart);
        }
        if (res.updateStatus.msg && !disableMessage) {
          showCartUpdateMessage(res.updateStatus);
        }
      });
    },
    [cart?.id, addDiscountCouponCall, setCart, showCartUpdateMessage],
  );
};

/* *********************************************************************************************************************
 * INTERNALS
 */
function ensureId(s: string | { id: string }): string {
  if (typeof s === 'string') return s;
  if (s && s !== null && s.id) return s.id;

  throw new Error('ensureId requires object with id prop or no empty string.');
}

/* *********************************************************************************************************************
 * TYPES
 */

export type ShoppingCartProduct = Product<ProductRecord, 'prices' | 'parameters'>;
export type ShoppingCartSubject = Overwrite<PricingCartSubject<ShoppingCartProduct>, { product: ShoppingCartProduct }>;
export type ShoppingCartContent = Overwrite<PricingCart<ShoppingCartProduct>, { subjects: ShoppingCartSubject[] }>;

export type ShoppingCartShippingMethod = ShoppingCartProduct & ShippingMethod;
export type ShoppingCartPaymentMethod = ShoppingCartProduct & PaymentMethod;

export interface ShoppingCart {
  acquiredDiscountCoupon?: {
    code: string;
    description: string;
  };
  cartContent: ShoppingCartContent;
  flatCart: Record<string, ShoppingCartSubject>;
  id: EntityId;
  owner?: string;
  sessions: string[];
  state: ShoppingCartState;
  subjectFindIndex: string[];
}

export type ShoppingCartState = 'active' | 'parked';

export interface ShoppingCartPreparationResult {
  cart: ShoppingCart | null;
  error?: any;
  status?: ShoppingCartPreparationResultStatus | undefined;
}
export type ShoppingCartPreparationResultStatus = 'cart-in-other-session';

export interface ShoppingCartUpdateCartCallParams {
  cartId: string;
  currency: string;
  data: ShoppingCartUpdateCartDataItemParams | ShoppingCartUpdateCartDataItemParams[];
  decimalPrecision?: number;
  /**
   * List of dynamic rules, that are enabled...
   */
  enabledDynamicRules?: string[];
  priceGroup?: string;
  pricesType?: PricesType;
}

export interface ShoppingCartUpdateCartDataItemParams {
  enableIndirect?: boolean;
  product?: string | AddToCartProduct;
  quantity?: number;
  ruleCode?: string;
  subjectId?: string;
}

export interface AddToCartProduct extends Partial<ProductQuantityLimits> {
  id: string;
  listPrice?: Multicurrency;
  listPriceWithTax?: Multicurrency;
  sku: string;
}

export interface ShoppingCartManipulationResult {
  cart: ShoppingCart;
  updateStatus: ShoppingCartManipulationResultUpdateStatus;
}

export interface ShoppingCartManipulationResultUpdateStatus {
  msg: ShoppingCartUdateCartShowMessage | null;
  qty?: number;
}

export type ShoppingCartUdateCartShowMessage =
  | 'all-removed'
  | 'item-added'
  | 'item-removed'
  | 'quantity-chaged'
  | 'invalid-coupon'
  | 'invalid-product'
  | 'invalid-product-quantity';

export interface ShoppingCartResetCartCallParams {
  cartId: string;
}

export type ShoppingCartDecisionChoices = 'empty' | 'merge' | 'take-over';

export interface ShoppingCartProcessUserDecisionCallParams {
  decision: ShoppingCartDecisionChoices;
  mergeWith?: {
    /** CartId to merge with */
    id: string;

    /**
     * TODO: not finaly specified
     *
     * Subject of cart to merge
     */
    subjects?: Record<string, any>[];
  };
  previousCartId: string;
}

export interface ShoppingCartUserDecitionResult {
  cart: ShoppingCart | null;
  error?: any;
  status: ShoppingCartUserDecitionResultStatus | undefined;
}

export type ShoppingCartUserDecitionResultStatus = 'created' | 'merged' | 'taked-over';
